import { Component } from 'react';
import { connectTeamsTheme } from "./../../../context/connectTeamsTheme";
import {
    Dialog,
    DialogSurface,
    DialogBody,
    DialogActions,
    Button,
    DialogTitle,
    Checkbox,
    DialogContent,
    DialogTrigger,
} from "@fluentui/react-components";
import * as apiCalls from '../../../logic/apiCalls';
import { DismissRegular } from '@fluentui/react-icons';

interface ReProcessMediaDialogProps {
    mediaId: string;
    open: boolean;
    onClose(): void;
}

interface ReProcessMediaDialogState {
    transcription: boolean,
    summarization: boolean,
    videoIndexing: boolean,
    messageDialog: MessageDialogProps,
}

interface MessageDialogProps {
    title: string;
    message: string;
    isOpen: boolean;
}

export default class ReProcessMediaDialogInternal extends Component<ReProcessMediaDialogProps, ReProcessMediaDialogState> {
    constructor(props: ReProcessMediaDialogProps) {
        super(props);
        this.state = {
            transcription: false,
            summarization: false,
            videoIndexing: false,
            messageDialog: {
                title: "Default title",
                message: "Default message",
                isOpen: false,
            },
        }
    }

    onClose = () => {
        this.props.onClose();
    }

    onCloseReProcessDialog = () => {
        this.props.onClose();
    }

    onCloseMessageDialog = () => {
        this.setState(prevState  =>
            ({ 
                ...prevState,
                messageDialog: {                    
                    ...prevState.messageDialog,
                    isOpen: false
                }

            }));        
    }

    onMessageDialog = (message: string, title: string) => {
        this.setState(prevState  =>
            ({ 
                ...prevState,
                messageDialog: {                    
                    ...prevState.messageDialog,
                    isOpen: true,
                    message: message,
                    title: title
                }
            }));
    }

    onConfirm = async () => {
        if (!this.state.transcription && !this.state.summarization && !this.state.videoIndexing) {
            this.onMessageDialog("Please select at least one option.", "Error");
            return;
        }

        var request = {
            transcription: this.state.transcription,
            summarization: this.state.summarization,
            videoIndexing: this.state.videoIndexing
        };
        return apiCalls.reprocessMedia(this.props.mediaId, request)
            .then((data) => {                
                this.onMessageDialog("Media successfully queued for re-processing. Depending on the selected operation and the duration of the media, it can take minutes to hours to before the re-process operation is finished. ", "Processed");
                this.onCloseReProcessDialog();
            })
            .catch((error) => {
                this.onMessageDialog(`Error while reprocessing media. ${error}`, "Error");
                this.onCloseReProcessDialog();
            });
    }

    getContentDialog = () => {
        return <>
            <div>
                Please select the actions you want to perform on the media:
                <br />
                <Checkbox label="Transcription" checked={this.state.transcription} onChange={(event, checked) => { this.setState({ transcription: !!checked.checked }) }} />
                <br />
                <Checkbox label="Video Indexing" checked={this.state.videoIndexing} onChange={(event, checked) => this.setState({ videoIndexing: !!checked.checked })} />
                <br />
                <Checkbox label="Summarization" checked={this.state.summarization} onChange={(event, checked) => this.setState({ summarization: !!checked.checked })} />
            </div>
        </>
    }

    render() {
        return (
            <>
                {/* Dialog component to show the re-process media dialog */}
                <Dialog open={this.props.open}>
                    <DialogSurface>
                        <DialogBody
                            style={{
                                width: "100%",
                                gridTemplateColumns: "1fr auto",
                                height: "100%",
                                maxHeight: "250px",
                                overflow: "auto",
                            }}
                        >
                            <DialogTitle
                                style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                }}
                            >
                                <p>Re-process media</p>
                                <Button
                                    onClick={this.onCloseReProcessDialog}
                                    appearance="transparent"
                                    icon={<DismissRegular />}
                                />
                            </DialogTitle>
                            <div>
                                Please select the actions you want to perform on the media:
                                <br />
                                <Checkbox label="Transcription" checked={this.state.transcription} onChange={(event, checked) => { this.setState({ transcription: !!checked.checked }) }} />
                                <br />
                                <Checkbox label="Video Indexing" checked={this.state.videoIndexing} onChange={(event, checked) => this.setState({ videoIndexing: !!checked.checked })} />
                                <br />
                                <Checkbox label="Summarization" checked={this.state.summarization} onChange={(event, checked) => this.setState({ summarization: !!checked.checked })} />
                            </div>
                            <DialogActions fluid>
                                <Button onClick={this.onCloseReProcessDialog} appearance="transparent">
                                    Cancel
                                </Button>
                                <Button onClick={this.onConfirm} appearance="primary">
                                    Confirm
                                </Button>
                            </DialogActions>
                        </DialogBody>
                    </DialogSurface>
                </Dialog>

                {/* Dialog component which is used to show message */}
                <Dialog open={this.state.messageDialog.isOpen}>
                    <DialogSurface>
                        <DialogBody>
                            <DialogTitle>{this.state.messageDialog.title}</DialogTitle>
                            <DialogContent>
                                {this.state.messageDialog.message}
                            </DialogContent>
                            <DialogActions>
                                <DialogTrigger disableButtonEnhancement>
                                    <Button appearance="primary" onClick={this.onCloseMessageDialog}>Close</Button>
                                </DialogTrigger>
                            </DialogActions>
                        </DialogBody>
                    </DialogSurface>
                </Dialog>
            </>
        )
    }
}

export const ReProcessMediaDialog = connectTeamsTheme(ReProcessMediaDialogInternal as any);