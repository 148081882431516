import React, { Component } from 'react';
import './TimeFacetPresentation.scss';
import moment from 'moment';
import { 
  Shifts20Regular as ShiftActivityIcon, 
  ChevronDown32Filled as ChevronDownIcon, 
  ChevronRight32Filled as ChevronRightIcon 
} from '@fluentui/react-icons';
import { Button, mergeClasses, Radio, RadioGroup,Field } from '@fluentui/react-components';
import DatePicker from './DatePickerPresentation';
import { connectTeamsTheme } from "./../../../context/connectTeamsTheme";
import { TimePicker } from "@fluentui/react-timepicker-compat"


class TimeFacetTemplate extends Component {

    constructor(props) {
        super(props);

        let dateTimeYesterday = moment().subtract(1, 'day');
        let dateTimeNow = moment();

        this.state = {
            isExpanded: true,
            fromTimeEditQueue: 0,
            fromDateTime: dateTimeYesterday,
            toTimeEditQueue: 0,
            toDateTime: dateTimeNow,
        }
    }
    
    handleSectionExpand = () => this.setState(prevState => ({ 
        isExpanded: !prevState.isExpanded 
    }))

    dateStringToMoment = (date) => {
        return moment(new Date(date));
    }

    reportFacetChange = (fromDateTime, toDateTime, selectedIndex) =>
    {
        let valueChanged = {fromDateTime: fromDateTime, toDateTime: toDateTime, selectedIndex: selectedIndex};

        this.props.onChangeFacet(this.props.name, valueChanged);
    }

    reportFromDateTimeChange = (fromDateTime, toDateTime) =>
    {
        let dateTimeNow = moment();
        if (fromDateTime > dateTimeNow) {
            fromDateTime = dateTimeNow.subtract(1, 'day');
        }

        if (fromDateTime > toDateTime) {
            toDateTime = fromDateTime.add(1, 'day');
        }

        this.reportFacetChange(fromDateTime, toDateTime, null);
    }

    reportToDateTimeChange = (fromDateTime, toDateTime) =>
    {
        let dateTimeNow = moment();
        if (toDateTime > dateTimeNow) {
            toDateTime = dateTimeNow;
        }

        if (fromDateTime > toDateTime) {
            fromDateTime = toDateTime.subtract(1, 'day');
        }
        
        this.reportFacetChange(fromDateTime, toDateTime, null);
    }

    onFromDateTimeChange = (fromDateTime) => {
        if (!fromDateTime || !fromDateTime.isValid() || fromDateTime > this.state.toDateTime) {
            return;
        }

        this.setState(prevState => {
            return {
                ...prevState,
                fromDateTime: fromDateTime,
                fromTimeEditQueue: prevState.fromTimeEditQueue + 1,
            }
        }, () => setTimeout(() => { 
            this.setState(prevState => {
                return {
                    ...prevState,
                    fromTimeEditQueue: prevState.fromTimeEditQueue - 1,
                }
            }, () => {
                if (this.state.fromTimeEditQueue === 0) {
                    this.reportFromDateTimeChange(fromDateTime, this.state.toDateTime);
                } 
            })
        }, 2000));
    }

    onToDateTimeChange = (toDateTime) => {
        if (!toDateTime || !toDateTime.isValid() || toDateTime < this.state.fromDateTime) {
            return;
        }

        this.setState(prevState => {
            return {
                ...prevState,
                toDateTime: toDateTime,
                toTimeEditQueue: prevState.toTimeEditQueue + 1,
            }
        }, () => setTimeout(() => { 
            this.setState(prevState => {
                return {
                    ...prevState,
                    toTimeEditQueue: prevState.toTimeEditQueue - 1,
                }
            }, () => {
                if (this.state.toTimeEditQueue === 0) {
                    this.reportToDateTimeChange(this.state.fromDateTime, toDateTime);
                } 
            })
        }, 2000));
    }

    onFromDateChange = (date) => {
        let currentTime = this.state.fromDateTime.format("HH:mm ZZ");
        let newFromDateTime = moment(date.format("D MMM yyyy") + " " + currentTime);

        if (!newFromDateTime || !newFromDateTime.isValid() || newFromDateTime > this.state.toDateTime) {
            return;
        }

        this.setState(prevState => ({ 
            ...prevState,
            fromDateTime: newFromDateTime,
        }));

        this.reportFromDateTimeChange(newFromDateTime, this.state.toDateTime);
      }

    onToDateChange = (date) => {
        let currentTime = this.state.toDateTime.format("HH:mm ZZ");
        let newToDateTime = moment(date.format("D MMM yyyy") + " " + currentTime);

        if (!newToDateTime || !newToDateTime.isValid() || newToDateTime < this.state.fromDateTime) {
            return;
        }

        this.setState(prevState => ({ 
            ...prevState,
            toDateTime: newToDateTime,
        }));

        this.reportFromDateTimeChange(this.state.fromDateTime, newToDateTime);
      }
    

    onInputTimeChange = (data) => {
        const time = {
            selectedTimeText:data.toString().trim()
        }
        return time;
    }  

    onFromTimeChange = (evt,data) => {
        if (data === null) {
            return;
        }
        const time = moment(data.selectedTimeText)
        let currentDate = this.state.fromDateTime.format("D MMM yyyy");
        let currentTimeZone = this.state.fromDateTime.format("ZZ");
        let newFromDateTime = moment(currentDate + " " + time._i + " " + currentTimeZone);
        this.onFromDateTimeChange(newFromDateTime);
      }

    onToTimeChange = (evt,data) => {
        if (data === null) {
            return;
        }
        const time = moment(data.selectedTimeText);
        let currentDate = this.state.toDateTime.format("D MMM yyyy");
        let currentTimeZone = this.state.toDateTime.format("ZZ");
        let newToDateTime = moment(currentDate + " " + time._i + " " + currentTimeZone);
        this.onToDateTimeChange(newToDateTime);
      }

    setChecked = (period, selectedIndex) => {
        let fromDateTime = moment().subtract(period);
        let toDateTime = moment();

        this.forceUpdate();
        this.reportFacetChange(fromDateTime, toDateTime, selectedIndex);
    }

    setCustomChecked = () => {
        this.forceUpdate();
        this.reportFacetChange(this.state.fromDateTime, this.state.toDateTime, null);
    }

    render() {
        const periodData = this.props.periodData;

        let customChecked = true;
        let checkboxChecked = false;

        
        let periodCheckboxes = periodData.map((periodEntry, index) => {
            if ( periodEntry.count <= 0 && periodEntry.showAlways === false ) {
                return null;
            }

            if (periodEntry.checked) {
                checkboxChecked = true;
                customChecked = false;
            }

            // Wait for search to complete before showing facet count values
            // (to prevent wrong number displayed during change of period)
            let searching = false;
            if (this.props.searchContext && this.props.searchContext.isExecutingSearch) {
                searching = true;
            }

            const countDisplay = periodEntry.count && periodEntry.displaycount && !searching ? `(${periodEntry.count})` : "";

            const radioButtonItems = [{
                key: periodEntry.periodIndex,
                label: `${periodEntry.displayName} ${countDisplay}`,
                value: periodEntry.periodIndex
            }]

            const isCheckedValue = periodEntry.checked ? +periodEntry.periodIndex : ''

            return (
                <li key={index + 1}>
                    <RadioGroup
                      layout
                      value={isCheckedValue}
                      onChange={(_, options) => this.setChecked(periodEntry.period, periodEntry.periodIndex)} 
                      >
                        {radioButtonItems.map(item => (
                          <Radio key={item.key} value={item.value} label={item.label} />
                        ))}
                      </RadioGroup>
                </li>
            );
        }); 

        const { handleSectionExpand, state } = this;
        // is isExpanded we display the section
        const { isExpanded } = state;
  
        return <section className={`search-fragment ${isExpanded ? 'expanded' : ''}`}>
            <header className={`${(checkboxChecked || customChecked) && ('active')}`} onClick={() => handleSectionExpand()}>
                <Button
                    appearance="transparent"
                    icon={isExpanded ? <ChevronDownIcon size="large" /> : <ChevronRightIcon size="large" />}
                />
                {this.props.displayName}
            </header>
            <div className="support-content">
                <ul>
                    {periodCheckboxes}
                    <li key={periodCheckboxes.length + 1}>
                        <Radio 
                            value="Custom"
                            label="Custom"
                            checked={customChecked} 
                            onClick={this.setCustomChecked} />
                    </li>
                    <li key={1} className="display--flex date-time-support">
                            <p className="title">
                                From
                            </p>
                            <DatePicker
                                className="datepicker"
                                value={this.state.fromDateTime}
                                onChange={(newDate) => this.onFromDateChange(newDate)}
                                maxDate={this.state.toDateTime}
                                context={this.props.context}
                            />
                        <Field name="fromTimePicker" className={`support-timepicker ${this.props.context.style === 1 ? 'dark' : 'light'}`}
                          style={{ borderColor: this.props.styles.brandColors.borderColor }}>
                            <TimePicker
                                className='time-input'
                                freeform
                                style={{
                                    border:"none",
                                    width:'200px',
                                    display:'flex',
                                    minWidth:"100%"
                                  }}
                                selectedTime={this.state.fromDateTime._d}
                                value={moment(this.state.fromDateTime).format("HH:mm")}
                                onTimeChange={this.onFromTimeChange}
                                onChange={(evt)=>{
                                    const time = this.onInputTimeChange(evt.target.value)
                                    this.onFromTimeChange(evt,time)
                                }}
                            />
                           <ShiftActivityIcon className={mergeClasses(this.props.styles.theme.icon, 'support-timepicker-icon')} /> 
                        </Field>
                    </li>
                    <li key={2} className="display--flex date-time-support">
                        <p className="title">
                            To
                        </p>
                        <DatePicker
                            value={this.state.toDateTime}
                            onChange={(newDate) => this.onToDateChange(newDate)}
                            minDate={this.state.fromDateTime}
                            maxDate={moment()}
                            context={this.props.context}
                        />
                        <Field name="toTimePicker" className={`support-timepicker ${this.props.context.style === 1 ? 'dark' : 'light'}`}
                          style={{ borderColor: this.props.styles.brandColors.borderColor }}>
                            <TimePicker
                                className='time-input'
                                style={{
                                  border:"none",
                                  width:'160px',
                                  display:'flex',
                                  minWidth:"100%"
                                }}
                                freeform
                                value={moment(this.state.toDateTime).format("HH:mm")}
                                onTimeChange={this.onToTimeChange}
                                onChange={(evt)=>{
                                    const time = this.onInputTimeChange(evt.target.value)
                                    this.onToTimeChange(evt,time)
                                }}
                            />
                           <ShiftActivityIcon className={mergeClasses(this.props.styles.theme.icon, 'support-timepicker-icon')} /> 
                        </Field>
                    </li>
                </ul>
            </div>
        </section>;
    }
}

const TimeFacet = connectTeamsTheme(TimeFacetTemplate);
export default TimeFacet;